.list {
  position: relative;
  width: 80%;
  margin: 40rem auto;
  margin-bottom: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 0rem;
}
.list:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-0%, -50%);
  height: 78%;
  width: 1.3rem;
  background: linear-gradient(180deg, #fbfbfb 0%, #badde7 100%);
  z-index: -10;
}
.ballGroup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  /* grid-template-columns: repeat(1fr , 10); */
  flex-direction: column;
  justify-content: space-evenly;
  gap: 8rem;
  align-items: center;
  height: 78%;
  z-index: -10;
}
@media (max-width: 75em) {
  .list {
    width: 90%;
  }
  .list:after {
    height: 74%;
    width: 1rem;
  }
}
@media (max-width: 37.5em) {
  .ballGroup {
    display: none;
  }
  .list:after {
    display: none;
  }
}
.button {
  align-self: flex-end;
  justify-self: flex-end;
}
