.button {
  position: relative;
  font-size: 1.6rem;
  color: var(--primary-color-dark);
  background-color: #fff;
  border-radius: var(--border-radius-30);
  text-align: center;
  margin-top: 1rem;
  box-shadow: var(--shadow-light);
  cursor: pointer;
  outline: none;
  border: none;
}
.button:hover,
.button:active {
  background-color: #f2f2f2;
  color: rgba(126, 176, 176, 0.7);
}
