.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-items: center;
  align-items: center;
  margin: 0rem auto;
  margin-top: 30rem;
}
.list .item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  background: var(--gray-gradient);
  padding: 8rem;
  gap: 4rem;
  margin-bottom: 16rem;
  max-width: 60vw;
}
.list .item h2 {
  font-size: 3.6rem;
  color: var(--gray-dark);
}
@media (max-width: 56.25em) {
  .list {
    margin-top: 45rem;
  }
  .list .item {
    flex-direction: column;
    margin-bottom: 12rem;
    text-align: center;
  }
}
@media (max-width: 37.5em) {
  .list {
    margin-top: 50rem;
  }
  .list .item {
    flex-direction: column;
    margin-bottom: 12rem;
  }
}
.list .item img {
  width: 25rem;
}
.list .content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1.4;
}
.list .item .content {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-light);
}
.list .item p {
  width: 120%;
}
.button {
  outline: none;
  border: none;
  padding: 1rem 6rem;
  align-self: flex-start;
}
@media (max-width: 56.25em) {
  .list .item img {
    width: 25rem;
  }
  .list .content {
    text-align: center;
  }
  .list .item .content {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--gray-light);
  }
  .list .item h2 {
    font-size: 3.6rem;
    color: var(--gray-dark);
    text-align: center;
  }
  .list .item p {
    text-align: center;
  }
}
