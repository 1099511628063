.card {
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary-gradient);
  filter: var(--shadow-heavy);
  color: white;
  padding: 1rem 4rem;
  border-radius: 30px;
  width: 80%;
  backdrop-filter: blur(20px);
}
@media (max-width: 56.25em) {
  .card {
    height: 18%;
  }
}
.card h1 {
  text-shadow: var(--text-shadow-green);
  text-align: left;
  line-height: 1;
}
.card h3 {
  max-width: 80rem;
  line-height: 1.3;
  font-weight: 500;
  text-shadow: var(--text-shadow-green);
  text-align: left;
  line-height: 1.2;
}
.card p {
  font-size: 2.4rem;
  max-width: 50rem;
  margin-top: 1rem;
  line-height: 1.3;
  text-shadow: var(--text-shadow-green);
  text-align: left;
}
.card .arrow {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/* .card .arrow svg {
  cursor: pointer;
}
.card .img {
  position: relative;
  width: 100%;
  height: 24rem;
}
.card .img svg {
  position: absolute;
  bottom: -10%;
  left: 35%;
}
.card .img img {
  position: absolute;
  top: -5%;
  left: 20%;
} */
.card a {
  position: absolute;
  right: 0;
  margin-top: 6rem;
}
@media (max-width: 37.5em) {
  .card a {
    position: absolute;
    right: 0;
    margin-top: 17rem;
  }
}
/* .card .button {
} */
