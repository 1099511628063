.wrapper {
  margin: 20rem auto;
  margin-top: 25rem;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  animation: moveInBottom 1s forwards;
}
@media (max-width: 75em) {
  .wrapper {
    padding: 5rem 5rem;
  }
}
@media (max-width: 56.25em) {
  .wrapper {
    width: 80%;
  }
}
@media (max-width: 37.5em) {
  .wrapper {
    width: 70%;
  }
}
.wrapper h2 {
  color: var(--gray-dark);
  display: block;
  text-align: center;
}
.wrapper p {
  margin-bottom: 2rem;
  color: var(--gray-light);
  font-size: 2.4rem;
  text-align: center;
  line-height: 1.3;
}
.form {
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form input {
  padding: 1rem 2rem;
  padding-right: 20rem;
  font-size: 1.8rem;
  color: var(--gray-light);
}
.form label {
  font-size: 1.8rem;
  font-weight: 500;
}
.form textarea {
  font-weight: 500;
  margin-right: 4rem;
  margin-right: 0 !important;
}
@media (max-width: 56.25em) {
  .form input {
    padding: 1rem 2rem;
    padding-right: 15rem;
  }
}
@media (max-width: 37.5em) {
  .form input {
    padding: 1rem 2rem;
    padding-right: 7rem;
  }
  .form textarea {
    padding: 0.5rem;
  }
}
.form .button {
  padding: 1rem 7rem;
  font-size: 2.4rem;
}
.buttonSignUp {
  display: block;
  margin: 2rem auto;
  padding: 1rem 7rem;
  font-size: 2.4rem;
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }
  80% {
  }
  100% {
    opacity: 1;
  }
}
