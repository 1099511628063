.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-items: center;
  align-items: center;
  margin: 30rem auto;
}
.list h2 {
  font-size: 3.6rem;
  color: var(--gray-dark);
  margin-bottom: 2.4rem;
}
.list .item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  background: var(--gray-gradient);
  padding: 8rem;
  gap: 4rem;
}
.list .item:not(:last-child) {
  margin-bottom: 16rem;
}
@media (max-width: 56.25em) {
  .list {
    margin-top: 40rem;
  }
  .list h2 {
    text-align: center;
  }
  .list .item {
    flex-direction: column;
  }
  .list .item:not(:last-child) {
    margin-bottom: 12rem;
  }
}
.list .item img {
  width: 25rem;
}
.list .content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1.4;
}
.list .item .text {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-light);
}
.list .item p {
  width: 110%;
}
@media (max-width: 56.25em) {
  .list .item p {
    text-align: center;
  }
}
