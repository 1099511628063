.content {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50rem;
}
@media (max-width: 56.5rem) {
  .content {
    margin-top: 60rem;
  }
}
@media (max-width: 37.5rem) {
  .content {
    margin-top: 70rem;
  }
}
.about {
}
.content h2 {
  color: var(--gray-dark);
  font-size: 3.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
.content h3 {
  color: var(--gray-dark);
  font-size: 3rem;
  font-weight: 300;
  margin: 2rem;
  line-height: 1.2;
}
.content p {
  color: var(--gray-light);
  font-size: 2rem;
  font-weight: 300;
  width: 90vw;
  max-width: 67.4rem;
}
@media (max-width: 56.5rem) {
  .content p {
    width: 80vw;
    margin: 0 10rem;
    max-width: none;
  }
  .content h3 {
    font-weight: 300;
    width: 80vw;
    margin: 2rem auto;
  }
}

.choice {
  margin-bottom: 10rem;
}
