.business {
  width: 100%;
  margin: 0rem auto;
  margin-top: 20rem;
  padding-bottom: 20rem;
}
.business h2 {
  font-size: 3.6rem;
  text-align: center;
  color: var(--gray-dark);
}
.list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  grid-template-rows: repeat(auto-fit, 0.5fr);
  width: 80%;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
}

.list .item {
  display: grid;
  grid-template-rows: 3fr 1fr;
  align-items: center;
  justify-items: center;
  grid-row-gap: 0rem;
}
/* @media (max-width: 56.25em) {
  .list {
    grid-template-columns: 2fr 2fr;
  }
} */
.list .item img {
  width: 25rem;
  background-color: #fff;
}
.list .item img:hover {
  background-color: rgb(230, 230, 230);
}
.list .item .text {
  width: 100%;
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1.4rem;
}
.list .item .text h4 {
  padding-top: 1rem;
  font-size: 2.4rem;
  color: #3d3d3d;
}
.list .item .text p {
  font-size: 1.6rem;
  margin: 2rem auto;
  color: var(--gray-light);
}
.business button {
  position: absolute;
  bottom: 12%;
  right: 10%;
}
@media (max-width: 75em) {
  .business button {
    bottom: 6%;
  }
}
