.ball {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: var(--secondary-color-light);
  color: var(--primary-color-dark);
}

.ball::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  width: 600%;
  height: 5px;
  background-color: var(--primary-color-dark);
}
.ballLeft {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: var(--secondary-color-light);
  color: var(--primary-color-dark);
}
.ballLeft::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  width: 400%;
  height: 5px;
  background-color: var(--primary-color-dark);
}

@media (max-width: 75em) {
  .ball {
    width: 40px;
    height: 40px;
  }
  .ball::after {
    width: 550%;
  }
  .ballLeft {
    width: 40px;
    height: 40px;
  }
  .ballLeft::after {
    width: 600%;
  }
}
.bigBall {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background-color: var(--secondary-color-light);
  font-size: 3rem;
  color: var(--primary-color-dark);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.bigBall::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  width: 300%;
  height: 5px;
  background-color: var(--primary-color-dark);
}
@media (max-width: 75em) {
  .bigBall::after {
    width: 400%;
  }
  .bigBall {
    width: 60px;
    font-size: 2.4rem;
    height: 60px;
  }
}
@media (min-width: 112.5em) {
  .ball::after {
    width: 1000%;
  }
  .ballLeft::after {
    width: 1000%;
  }
  .bigBall::after {
    width: 650%;
  }
}
