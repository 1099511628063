.button {
  position: relative;
  filter: var(--shadow-heavy);
  background: var(--primary-gradient);
  border-radius: var(--border-radius-30);
  padding: 1.7rem 2.4rem 1.7rem 7rem;
  outline: none;
  border: none;
  color: white;
  font-size: 2.4rem;
  cursor: pointer;
  transition: all 0.3s;
  outline: none;
  border: none;
}
.button::after {
  content: "";
  background-color: white;
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: 2.4rem;
  transform: translateY(-50%);
  border-radius: 100px;
  width: 2.6rem;
  height: 2.6rem;
}
.button:hover,
.button:active {
  background: linear-gradient(
    270.37deg,
    #5cefae 2.19%,
    rgba(45, 109, 129, 0.36) 190.29%
  );
}
